import {
	ApiClient,
	CountSmartwordsRequest,
	DemoWebSiteCreateRequest,
	PageScriptPublishRequest,
	PageScriptPublishRequestDto, PageTranslationInfoByPathRequest, PageTranslationSelection,
	PageTranslationStatisticsCalculateRequest,
	PageTranslationStatisticsUpdateRequest, ScriptPublicationValidationResult,
	SinglePagePublishTaskInfo,
	SmartcatSyncRequestDto,
	SmartcatSyncResult,
	SmartcatSyncTaskResponse,
	WebPagePublishResponse,
	WebPageTranslationsCleanupRequest,
	WebSiteSettingsDto,
	WebSiteStartCrawlRequest, WebSiteStatsInfo,
	WebTranslationMixpanelEvent,
} from '@/shared';
import { delay } from '@/shared/utils/delay';

export class SmartcatApiService {
	constructor(private _client: ApiClient) {
	}

	public async createDemo(request: DemoWebSiteCreateRequest) {
		return await this._client.createDemo(request);
	}

	public async crawlWebSite(request: WebSiteStartCrawlRequest) {
		return await this._client.crawlWebSite(request);
	}

	public async getCrawlInfo(webSiteId: string) {
		return await this._client.getCrawlInfo(webSiteId);
	}

	public async cancelCrawl(webSiteId: string) {
		return await this._client.cancelCrawl(webSiteId);
	}

	public async syncPageWithSmartcat(req: SmartcatSyncRequestDto) {
		const syncResponse = await this._client.createPageSyncTask(req);
		const results = await this.waitSyncTasks(syncResponse);
		return results;
	}

	public async cleanupPageTranslations(req: WebPageTranslationsCleanupRequest) {
		return await this._client.cleanupPageTranslations(req);
	}

	public async beginImportTranslationsFromSmartcat(req: SmartcatSyncRequestDto) {
		const syncResponse = await this._client.importTranslationsFromSmartcat(req);
		const results = await this.waitSyncTasks(syncResponse);
		return results;
	}

	public async validatePublication(webSiteId: string, pageUrl?: string) {
		if (window?.location.href.includes('skip-script-validation')) {
			return ScriptPublicationValidationResult.ScriptIsOk;
		}
		return await this._client.validatePublication(webSiteId, pageUrl);
	}


	public async enableSharing(webSiteId: string) {
		await this._client.enableSharing(webSiteId);
	}

	public async saveStats(req: PageTranslationStatisticsUpdateRequest) {
		await this._client.saveStats(req);
	}

	public async publish(req: PageScriptPublishRequestDto) {
		const response = await this._client.publish(req);
		await this.waitPagePublishTasks(response);
	}

	public async publishSelection(webSiteId: string, selection: PageTranslationSelection) {
		return await this._client.publishSelectedPages(webSiteId, selection);
	}

	public async publishByPath(req: PageScriptPublishRequest) {
		const response = await this._client.publishByPath(req);
		await this.waitPagePublishTasks(response);
	}

	public async getPublishedScript() {
		return await this._client.getPublishedScript();
	}

	public async setPublishedScript(v: string) {
		await this._client.setPublishedScript(v);
	}

	public async getWebPagePublishInfo(webSiteId: string, pagePath: string) {
		return await this._client.getWebPagePublishInfo(webSiteId, pagePath);
	}

	public async getSettings() {
		return await this._client.getSettings();
	}

	public async updateSettings(webSiteId: string, model: WebSiteSettingsDto) {
		return await this._client.updateSettings(webSiteId, model);
	}

	public async getSmartwordsInfo(webSiteId: string) {
		return await this._client.getSmartwordsInfo(webSiteId);
	}

	public async getWebSiteStats(webSiteId: string): Promise<WebSiteStatsInfo> {
		return await this._client.getWebSiteStats(webSiteId);
	}

	public async getWebSite(webSiteId: string) {
		return await this._client.getWebSite(webSiteId);
	}

	public async syncLanguages(webSiteId: string) {
		return await this._client.syncLanguages(webSiteId);
	}

	public async addLanguage(webSiteId: string, language: string) {
		return await this._client.addLanguage(webSiteId, language);
	}

	public async getPagesByPath(req: PageTranslationInfoByPathRequest) {
		return await this._client.getPagesByPath(req);
	}

	public async publishTranslator(webSiteId: string) {
		return await this._client.publishTranslator(webSiteId);
	}

	public async getPublishInfo(webSiteId: string) {
		return await this._client.getPublishInfo(webSiteId);
	}

	public async getWebSitePublishProgress(webSiteId: string) {
		return await this._client.getWebSitePublishProgress(webSiteId);
	}

	public async getWebSiteScript(webSiteId: string) {
		return await this._client.getWebSiteScript(webSiteId);
	}

	public async countRequiredSmartwords(model: CountSmartwordsRequest) {
		return await this._client.countRequiredSmartwords(model);
	}

	public async getMixpanelSettings() {
		return await this._client.getMixpanelSettings();
	}

	async calculateStats(req: PageTranslationStatisticsCalculateRequest) {
		return await this._client.calculateStats(req);
	}

	public async trackMixpanelEvent(model: WebTranslationMixpanelEvent) {
		return await this._client.trackMixpanelEvent(model);
	}

	getWebSiteProject(webSiteId: string) {
		return this._client.getWebSiteProject(webSiteId);
	}

	private async waitSyncTasks(syncResponse: SmartcatSyncTaskResponse) {
		const results: SmartcatSyncResult[] = [];
		const timeLimit = 10000;
		let elapsed = 0;
		for (const taskId of syncResponse.taskIds) {
			let result: SmartcatSyncResult = null;
			let retries = 0;

			while (!this.isFinished(result) && elapsed < timeLimit) {
				result = await this._client.getPageSyncResult(taskId);
				if (++retries > 60) {
					throw new Error('Sync timeout');
				}
				await delay(1000);
				elapsed += 1000;
			}
			results.push(result);
		}
		return results;
	}

	private isFinished(result: SmartcatSyncResult) {
		if (!result || !result.items || !result.items.length) {
			return false;
		}
		return result.items.length > 0;
	}

	private async waitPagePublishTasks(publishResponse: WebPagePublishResponse) {
		const results: SinglePagePublishTaskInfo[] = [];
		for (const taskId of publishResponse.taskIds) {
			let result: SinglePagePublishTaskInfo = null;
			let retries = 0;

			while (!result || !result.isCompleted || !result.isFailed) {
				result = await this._client.getPagePublishTaskInfo(taskId);
				if (result.isFailed) {
					throw new Error(`Publish task ${result.isFailed} failed`);
				}
				if (result.isCompleted) {
					break;
				}
				if (++retries > 10) {
					throw new Error(`Publish task ${result.isFailed} timeout`);
				}
				await delay(1000);
			}
			results.push(result);
		}
		return results;
	}

}
