export class AutoTranslationEnabledChecker {
	public doesContentUrlMatchTranslationRules(
		currentUrl: string,
		webSiteHost: string,
		rules: string[]): boolean {

		if (webSiteHost.startsWith("http://") || webSiteHost.startsWith("https://")) {
			webSiteHost = this.tryGetHost(webSiteHost);
		}

		rules = rules || [];
		const host = this.tryGetHost(currentUrl) || webSiteHost;
		const path = this.tryGetPath(currentUrl);

		const blacklistRules = rules.filter(r => r.startsWith('-')).map(r => r.substring(1).trim());
		for (const rule of blacklistRules) {
			const ruleHost = this.tryGetHost(rule) || webSiteHost;
			const rulePath = this.tryGetPath(rule);
			if (this.isMatch(host, ruleHost) && this.isMatch(path, rulePath)) {
				return false;
			}
		}

		const whitelistRules = rules.filter(r => !r.startsWith('-'));

		if (whitelistRules.length == 0) {
			return this.isMatch(host, webSiteHost);
		}

		for (const rule of whitelistRules) {
			const ruleHost = this.tryGetHost(rule) || webSiteHost;
			const rulePath = this.tryGetPath(rule);
			if (this.isMatch(host, ruleHost) && this.isMatch(path, rulePath)) {
				return true;
			}
		}
		return false;
	}

	private isMatch(path: string, pattern: string) {
		if (!pattern) {
			return true;
		}
		let regex = '^';
		for (let i = 0; i < pattern.length; i++) {
			if (pattern[i] === '*') {
				regex += '.*';
			} else {
				const escaped = pattern[i].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
				regex += escaped;
			}
		}
		const reg = new RegExp(regex);
		return reg.test(path) || reg.test(path + '/');
	}


	private tryGetPath(currentUrl: string) {
		if (!currentUrl) {
			return currentUrl;
		}
		currentUrl = currentUrl.replace(/\*/g, '__ASTERISK__');
		let path: string = null;
		if (currentUrl.startsWith('http:') || currentUrl.startsWith('https:')) {
			path = new URL(currentUrl).pathname;
		} else {
			path = currentUrl;
		}
		path = path.replace(/\/$/, '');
		path = path.replace(/^\//, '');
		return path.replace(/__ASTERISK__/g, '*');
	}

	private tryGetHost(v: string) {
		if (!v) {
			return v;
		}
		v = v.replace(/\*/ig, '__ASTERISK__');
		if (v.startsWith('http:') || v.startsWith('https:')) {
			if (!new URL(v).host) {
				throw new Error(v + "can't be parsed as URL" + new URL(v).host + "!");
			}
			return new URL(v).host.replace(/__ASTERISK__/ig, '*');
		}
		return null;
	}
}
